@import '../../scss/mixin';



.navigation{
  position: fixed;
  display: block;
  top: 0;
  z-index: 100;
  width: 100%;
  transition: all .5s,;
  background-color: rgba(34, 34, 39, 1);
  height: 60px;
  border-bottom: solid 1px #222227;

  &.nav__lg{
    background: transparent;
    background-image: -webkit-linear-gradient(165deg, rgba(37,4,30,.4) 0%, rgba(22,21,26,0.5) 100%);
    background-image: -moz-linear-gradient(165deg, rgba(37,4,30,.4) 0%, rgba(22,21,26,0.5) 100%);
    background-image: -ms-linear-gradient(165deg, rgba(37,4,30,.4) 0%, rgba(22,21,26,0.5) 100%);
    background-image: -o-linear-gradient(165deg, rgba(37,4,30,.4) 0%, rgba(22,21,26,0.5) 100%);
    background-image: linear-gradient(165deg, rgba(37,4,30,.4) 0%, rgba(22,21,26,0.5) 100%);

    height: 100px;
    .navigation__body{
      .nav__logo{
        margin-left: 40px;
        svg{
          height: 30%;
        }
      }
      .nav__menu{
        margin-left: 120px;
        .nav__menu__item{
          margin: 0 22px;
          font:400 20px/22px 'Inter', sans-serif;
        }
      }
    }

  }

  // background: red;
  .navigation__body{
    @include flex-row;
    @include justify-start;
    height: 100%;
    .nav__logo{
      @include flex-row;
      width: auto;
      height: 100%;
      margin-left: 30px;
      svg{
        height: 40%;
        width: auto;
      }
    }
    .nav__menu{
      @include flex-row;
      width: auto;
      margin-left: 80px;
      height: 100%;
      .nav__menu__item{
        @include display-block;
        margin: 0 15px;
        color: #bdbdbd;
        font:400 18px/18px 'Inter', sans-serif;
        svg{display: none;}
        &:hover{
          color: #fff;
        }
        &.active{
          color: #fff;
        }
      }
    }
    .login__access{
      @include display-block;
      @include psuedo-bg(-1px,5px);
      &:before{
        z-index: -1;
        @include gradient-border-bg(#adf8fc,#f3b6e5);

      }
      width: auto;
      position: absolute;
      right: 30px;
      background: #16151a;
      font:600 18px/18px 'Inter', sans-serif;
      padding: 8px 14px;
      border-radius: 5px;

      span{
        @include display-block;
        @include gradient-text(#adf8fc,#f3b6e5);
        width: max-content;
      }
      &:hover{
        &:before,span{
          background: #ddd;
        }
      }
    }
  }


  @media(max-width:992px){
    height: 50px;
    .navigation__body{
      .nav__logo{
        margin-left: 20px;
      }
      .nav__menu{
        margin-left: 60px;
        .nav__menu__item{
          margin: 0 15px;
          font:400 16px/18px 'Inter', sans-serif;
        }
      }
      .login__access{
        right: 20px;
        font:600 16px/18px 'Inter', sans-serif;
        padding: 6px 10px;
      }
    }
    &.nav__lg{
      height: 80px;
      .navigation__body{
        .nav__logo{
          margin-left: 20px;
        }
        .nav__menu{
          margin-left: 60px;
          .nav__menu__item{
            margin: 0 15px;
            font:400 18px/20px 'Inter', sans-serif;
          }
        }
      }
    }
  }
  @media(max-width:768px){
    .navigation__body{
      .nav__logo{
        margin-left: 10px;
      }
      .nav__menu{
        margin-left: 30px;
        .nav__menu__item{
          margin: 0 12px;
        }
      }
      .login__access{
        right: 10px;
      }
    }
    &.nav__lg{
      .navigation__body{
        .nav__logo{
          margin-left: 10px;
        }
        .nav__menu{
          margin-left: 30px;
          .nav__menu__item{
            margin: 0 12px;
            font:400 16px/18px 'Inter', sans-serif;
          }
        }
      }
    }
  }
  @media(max-width:576px){
    .navigation__body{
      .nav__logo{
        margin-left: 20px;
        svg{
          height: 50%;
        }
      }
      .login__access{
        right: 20px;
        padding: 6px 14px;
      }

      .nav__menu{
        position: fixed;
        bottom: 0;
        left: 0;
        margin: 0;
        height: 60px;
        width: 100%;
        .nav__menu__item{
          @include flex-column;
          @include align-center;
          height: 100%;
          background: #222227;
          padding: 8px 0;
          border-top: 2px solid #444;
          border-bottom: none !important;

          font:400 12px/15px 'Inter', sans-serif !important;
          svg{
            display: block;
            margin-bottom: 2px;
            fill:#bdbdbd;
            width: auto;
            height: 20px;
            margin-bottom: 4px;
          }
          &:hover{
            color: #fff;
            border-color:#6164ff;
          }
          &.active{
            color: #fff;
            border-color:#888;
            svg{
              fill:#fff;
            }
          }
        }
      }
    }
    &.nav__lg{
      .navigation__body{
        .nav__logo {
          margin-left: 20px;
        }
        .nav__menu{
          margin: 0;
          .nav__menu__item{
            margin: 0;
          }
        }
      }
    }
  }
}

button.nav__menu__item{
  height: 100%;
  &:focus,&:hover{
    color: #fff;
    .nav__more__content{
      display: flex;
    }
  }
}
.nav__more__content{
  @include flex-row;
  @include align-start;
  display: none;
  position: absolute;
  width: auto;
  left: 50%;
  top: calc(100% - 10px);
  transform: translateX(-50%);
  background: #222227;
  padding: 20px;
  border: 1px solid #444;
  border-radius: 10px;
  .nav__more__list{
    @include display-block;
    width: auto;
    text-align: center;
    margin: 0 20px;

    &:first-child{
      text-align: left;
      margin-left: 0;
      .nav__more__list__item{
        @include justify-start;
      }
    }
    &:last-child{
      text-align: right;
      margin-right: 0;
      .nav__more__list__item{
        @include justify-end;
      }
    }

    .nav__more__list__head{
      @include display-block;
      font:500 18px/22px 'Inter', sans-serif;
      margin-bottom: 20px;
      color: #888;
    }

    .nav__more__list__item{
      @include flex-row;
      color: #bdbdbd;
      font:400 15px/18px 'Inter', sans-serif;
      margin-bottom: 12px;
      &:last-child{
        margin-bottom: 0;
      }
      svg{
        display: block !important;
        width: 18px;
        fill:#888;
        margin-right: 3px;
      }
      &:hover{
        color: #fff;
        svg{
          fill:#ccc;
        }
      }
      &.active{
        color: #fff;
      }
    }
  }
  @media(max-width:768px){
    padding: 12px;
    border-radius: 8px;
    .nav__more__list{
      margin: 0 12px;

      .nav__more__list__head{
        font:500 16px/20px 'Inter', sans-serif;
        margin-bottom: 15px;
      }

      .nav__more__list__item{
        font:400 14px/18px 'Inter', sans-serif;
        svg{
          width: 16px;
        }
      }
    }
  }
  @media(max-width:576px){
    top: inherit;
    left: inherit;
    transform: none;
    right: 10px;
    bottom: calc(100% + 10px);
  }
}


.nologin__box{
  @include flex-column;
  height: 100%;

  h3{
    @include display-block;
    font:500 20px/25px 'Inter', sans-serif;
    margin-bottom: 15px;
    color: #ccc;
  }
  p{
    @include display-block;
    font:400 14px/22px 'Inter', sans-serif;
    color: #ccc;
    margin-bottom: 20px;
  }
  span{
    @include display-block;
    width: 95%;
    font:400 13px/16px 'Inter', sans-serif;
    color: #bbb;
    margin-top: 20px;
  }
  >a{
    @include display-block;
    width: 70%;
    background: #000;
    color: #ccc;
    font:400 14px/16px 'Inter', sans-serif;
    border: solid 1px #555;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;

    &:hover{
      color: #adf8fc;
      border-color: #adf8fc;
    }
  }
  .nologin__box__app{
    @include flex-row;
    @include justify-space-between;
    width: 70%;
    a{
      @include display-block;
      width: calc(50% - 4px);
      img{
        width: 100%;
      }
    }
  }
  .tronlink__logo{
    width: 100%;
    height: auto;
  }
  @media(max-width:576px){
    span,>a,.nologin__box__app{
      width: 100%;
    }
  }
}




.header__profile{

  @include display-block;
  position: absolute;
  width: auto;
  right: 30px;
  height: 100%;

  .login__profile{
    @include flex-row;
    color: #ccc;
    height: 100%;
    cursor: pointer;
    > svg{
      position: relative;
      display: block;
      width: 40px;
      fill:#888;
      padding: 5px;
      border-radius: 50%;
      border: solid #444 2px;
      z-index: 1;
      background: #16151a;
    }
    .login__switch{
      @include flex-row;
      background: #444;
      height: 30px;
      margin-left: -10px;
      padding: 0 0 0 15px;
      border-radius:0 15px 15px 0;
      p{
        @include display-block;
        width: max-content;
        color: #fff;
        font:500 13px/13px 'Ubuntu', sans-serif;
        &.gradient__text{
          @include gradient-text(#adf8fc,#f3b6e5);
          font:600 13px/13px 'Ubuntu', sans-serif;
        }
      }
      svg{
        margin-top: 2px;
        fill:#ccc;
        width: 30px;
      }
    }
  }

  .header__profile__menu{
    position: absolute;
    display: none;
    right: -20px;
    width: 100%;
    min-width: 220px;
    background: #16151a;
    border: solid #222227 2px;
    border-radius: 0 0 10px 10px;

    .profile__option{
      position: relative;
      display: block;
      padding: 10px 5px;
      color: #aaa;
      border-bottom: solid #222227 1px;
      font:400 16px/16px 'Ubuntu', sans-serif;
      svg{
        width: 25px;
        fill:#aaa;
        margin: 0 15px;
      }
      &:hover{
        color:#fff;
        svg{fill:#fff;}
      }
    }
  }
  &:hover{
    svg{fill:#fff;}
    .header__profile__menu{
      display: block;
    }
  }
  @media(max-width:768px){
    right: 20px;
    .header__profile__menu{
      right: -15px;
      min-width: 180px;
      border-radius:0 0 6px 6px;
      border-width: 1px;
    }
  }
}



// .nav__menu__item:hover .more__btn__popup{
//   display: block;
// }
