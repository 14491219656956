
@import '../../scss/mixin';


.details__body{
  @include display-block;
  @include gradient-border-bg(#adf8fc,#f3b6e5);
  padding: 1px;
  border-radius: 10px;

  .details__body__header{
    @include flex-row;
    @include justify-space-between;
    background: #16151a;
    border-radius: 10px 10px 0 0;
    padding: 20px 25px;
    margin-bottom: 1px;

    .details__body__header__name{
      @include flex-row;
      width: auto;
      overflow: hidden;
      p{
        @include display-block;
        word-break: break-all;
        text-align: left;
        width: auto;
        font:400 30px/35px 'Inter', sans-serif;
        color: #aaa;
      }
      svg{
        @include display-block;
        flex-shrink: 0;
        margin: 0 10px;
        width: 20px;
        height: auto;
        fill:#555;
        &:hover{
          fill:#fff;
        }
      }
      span{
        @include display-block;
        width: auto;
        margin-left: 8px;
        font:400 14px/16px 'Inter', sans-serif;
        color: #aaa;
      }
    }
    .details__body__header__tabs{
      @include flex-row;
      flex-shrink: 0;

      width: auto;
      border-radius: 6px;
      border: solid 1px #364a65;
      overflow: hidden;
      a{
        @include display-block;
        width: auto;
        font:400 16px/18px 'Inter', sans-serif;
        text-align: center;
        color: #aaa;
        padding: 10px 20px;
        background: #222227;
        border-right: solid 1px #364a65;
        &:last-child{
          border: none;
        }
        &:hover{
          background: #005777;
          color: #ccc;
        }
        &.active{
          background: #364a65;
          color: #fff;
        }
      }
    }
  }

  .details__body__info{
    @include display-block;
    background: #16151a;
    border-radius: 0 0 10px 10px;
    padding: 20px 25px;
  }

  @media(max-width:992px){
    .details__body__header{
      border-radius: 10px 10px 0 0;
      padding: 20px 15px;

      .details__body__header__name{
        p{
          font:400 22px/28px 'Inter', sans-serif;
        }
        svg{
          margin: 0 6px;
          width: 16px;
        }
      }
    }
    .details__body__info{
      padding: 20px 15px;
    }
  }
  @media(max-width:768px){
    .details__body__header{
      flex-wrap: wrap;
      border-radius: 10px 10px 0 0;

      .details__body__header__tabs{
        width: 100%;
        margin-top: 10px;
        a{
          width: 100%;
          font:400 14px/16px 'Inter', sans-serif;
        }
      }
    }
    .details__body__info{
      padding: 15px 10px;
    }
  }
}



.address__view{
  @include flex-row;
  @include justify-start;
  width: auto;
  overflow: hidden;

  .address__view__text{
    @include display-block;
    @include ellipsis-text;
    text-align: left;
    width: auto;
    font:400 16px/18px 'Inter', sans-serif;
    color: #aaa;
  }
  a.address__view__text:hover{
    text-decoration: underline;
    color: #ccc;
  }
  .address__view__copy{
    @include display-block;
    width: auto;
    svg{
      @include display-block;
      flex-shrink: 0;
      margin: 0 4px;
      width: 14px;
      height: auto;
      fill:#777;
      &:hover{
        fill:#fff;
      }
    }
  }
  .address__view__copied{
    @include display-block;
    width: auto;
    margin-left: 6px;
    font:400 14px/16px 'Inter', sans-serif;
    color: #aaa;
  }
  @media(max-width:768px){
    // background: red;
    .address__view__text{
      font:400 14px/16px 'Inter', sans-serif;
    }
    .address__view__copy{
      svg{
        margin: 0 6px 0 0;
        width: 12px;
      }
    }
    .address__view__copied{
      margin: 0 3px 0 2px;
      font:400 12px/14px 'Inter', sans-serif;
    }
  }
}
