@property --angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
}
@property --stretch {
	syntax: '<number>';
	initial-value: 0;
	inherits: false;
}

@-webkit-keyframes rotate-angle {
	from {--angle: 0deg;}
	to {--angle: 360deg;}
}
@keyframes rotate-angle {
	from {--angle: 0deg;}
	to {--angle: 360deg;}
}


@-webkit-keyframes stretch-bg {
	0%,100% {--stretch: 0;}
	50% {--stretch: 1;}
}
@keyframes stretch-bg {
	0%,100% {--stretch: 0;}
	50% {--stretch: 1;}
}

@mixin nostyle-block{
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}
@mixin display-block($w:100%,$m:0,$p:0){
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}


@mixin flex-row{
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	width: 100%;
	padding: 0;
	margin: 0;
}

@mixin flex-column{
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;

	-webkit-box-pack: start;
	-ms-flex-pack: start;
	 justify-content: flex-start;

	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 100%;
  padding: 0;
  margin: 0;
}

@mixin justify-start{
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
@mixin justify-end{
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
@mixin justify-space-between{
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@mixin justify-center{
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

@mixin align-start{
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
@mixin align-center{
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@mixin ellipsis-text{
	overflow: hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	word-wrap: break-word;
}



@mixin gradient-border($s:-1px,$r:10px,$c:#222227){
  &::after {
    position: absolute;
    top: $s;
    left: $s;
    // width: 100%;
    // height: 100%;
    bottom: $s;
    right: $s;
    background: $c;
    content: '';
    z-index: -1;
    border-radius: $r;
		-webkit-animation: 4s gradientBorder ease-out infinite;
	  -moz-animation: 4s gradientBorder ease-out infinite;
	  -o-animation: 4s gradientBorder ease-out infinite;
	  -ms-transition: 4s gradientBorder ease-out infinite;
    animation: gradientBorder 4s ease-out infinite;
  }
}

@mixin gradient-border-bg($c1:#ffffa2,$c2: #adf8fc){
	background: $c1;
	background-image: linear-gradient(135deg,$c1, $c2);


	@supports (background:paint(something)){
		background-image: -webkit-gradient(linear, left top, left bottom, from(var(--angle)), color-stop($c1), to($c2));
		background-image: -webkit-linear-gradient(var(--angle), $c1 50%, $c2) ;
		background-image: -o-linear-gradient(var(--angle), $c1 50%, $c2) ;
		background-image: linear-gradient(var(--angle), $c1 50%, $c2) ;
		-webkit-animation: 5s rotate-angle linear infinite;
		animation: 5s rotate-angle linear infinite;
	}
}

@mixin gradient-dummy-bg($c1:#222227,$c2: #323239){
	background: $c2;
	background-image: linear-gradient(315deg,$c1, $c2);

	@supports (background:paint(something)){
		background-image: -webkit-linear-gradient(315deg, $c1 0%,$c2 calc(var(--stretch) * 100%), $c1 100%);
		background-image: -o-linear-gradient(315deg, $c1 0%,$c2 calc(var(--stretch) * 100%), $c1 100%);
		background-image: linear-gradient(135deg, $c1 0%,$c2 calc(var(--stretch) * 100%), $c1 100%);
		-webkit-animation: 4s stretch-bg ease-out infinite;
		animation: 4s stretch-bg ease-in-out infinite;
	}

}

@mixin gradient-text($c1:#ffffa2,$c2:#adf8fc){


  color: $c1;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text !important;
	background-clip: text !important;

	background: $c1;
	background-image: linear-gradient(135deg,$c1, $c2);

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; /* Standard syntax */

	@supports (background:paint(something)){
		background-image: -webkit-gradient(linear, left top, left bottom, from(var(--angle)), color-stop($c1), to($c2));
		background-image: -webkit-linear-gradient(var(--angle), $c1 50%, $c2) ;
		background-image: -o-linear-gradient(var(--angle), $c1,$c2) ;
		background-image: linear-gradient(var(--angle), $c1, $c2);

		-webkit-animation: 6s rotate-angle ease-out infinite;
		animation: rotate-angle 6s linear infinite;
	}

}



@mixin external-margin($m : 10px){
  margin: $m;
  width: calc(100% - 2*$m);
  height: calc(100% - 2*$m);
}




@mixin scroll-bar($w:4px,$d:0px){
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	 &::-webkit-scrollbar {
		width: $w;
		background: #00011d;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
		margin: $d 0;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #364a65;
	}
}

@mixin scroll-bar2($w:4px,$d:0px){
	@include scroll-bar($w,$d);
	 &::-webkit-scrollbar {
		background: transparent;
	}
}

@mixin psuedo-bg($w:2px,$r:8px){
	&:before{
		position: absolute;
		display: block;
		content: '';
		background: #16151a;
		top: $w;
		left: $w;
		right: $w;
		bottom: $w;
		border-radius: $r;
	}

}
