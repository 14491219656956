@import '../../scss/mixin';


.footer{
  @include display-block;
  margin-top: 100px;
  .footer_shadow{
    position: absolute;
    bottom: calc(100% - 5px);
    left: 0%;
    width: 100%;
    height: 50px;
    background-image: -webkit-linear-gradient(0deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);
    background-image: -moz-linear-gradient(0deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);
    background-image: -ms-linear-gradient(0deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);
    background-image: -o-linear-gradient(0deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);
    background-image: linear-gradient(0deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);

    -webkit-clip-path: polygon(30px 0%, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
    -moz-: polygon(30px 0%, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
    -ms-: polygon(30px 0%, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
    -o-: polygon(30px 0%, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
    clip-path: polygon(30px 0%, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
  }
  .footer__content{
    @include flex-row;
    @include justify-space-between;
    padding: 25px;

    background-image: -webkit-linear-gradient(165deg, rgba(37,4,30,1), rgba(22,21,26,1));
    background-image: -moz-linear-gradient(165deg, rgba(37,4,30,1), rgba(22,21,26,1));
    background-image: -ms-linear-gradient(165deg, rgba(37,4,30,1), rgba(22,21,26,1));
    background-image: -o-linear-gradient(165deg, rgba(37,4,30,1), rgba(22,21,26,1));
    background:linear-gradient(165deg, rgba(37,4,30,1), rgba(22,21,26,1));
    border: solid 1px #364a65;
    border-radius: 10px 10px 0 0;



    .footer__social{
      @include flex-row;
      width: auto;
      a{
        margin: 0 10px;
        svg{
          fill:#aaa;
          height: 30px;
        }
        &:hover{
          svg{fill:#fff;}
        }
      }
    }
    .footer__copyright{
      @include display-block;
      width: auto;
      color: #ccc;
    }
  }
  @media(max-width:576px){
    margin-top: 80px;


    .footer_shadow{
      height: 30px;
      -webkit-clip-path: polygon(20px 0%, calc(100% - 20px) 0%, 100% 100%, 0% 100%);
      -moz-: polygon(20px 0%, calc(100% - 20px) 0%, 100% 100%, 0% 100%);
      -ms-: polygon(20px 0%, calc(100% - 20px) 0%, 100% 100%, 0% 100%);
      -o-: polygon(20px 0%, calc(100% - 20px) 0%, 100% 100%, 0% 100%);
      clip-path: polygon(20px 0%, calc(100% - 20px) 0%, 100% 100%, 0% 100%);
    }

    .footer__content{
      @include justify-center;

      margin-bottom: 60px;
      padding: 20px 10px;
      -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
      .footer__social{
        width: 100%;
        margin-bottom: 10px;
      }
      .footer__copyright{
        width: 100%;
        text-align: center;
      }
    }

  }
}
