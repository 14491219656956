@import '../../scss/mixin';



.home__banner{
  @include display-block;
  padding: 100px 0;
  background: rgb(37,4,30);
  background: -webkit-linear-gradient(165deg, rgba(37,4,30,0.6), rgba(22,21,26,1));
  background: -moz-linear-gradient(165deg, rgba(37,4,30,0.6), rgba(22,21,26,1));
  background: -ms-linear-gradient(165deg, rgba(37,4,30,0.6), rgba(22,21,26,1));
  background: -o-linear-gradient(165deg, rgba(37,4,30,0.6), rgba(22,21,26,1));
  background:linear-gradient(165deg, rgba(37,4,30,0.6), rgba(22,21,26,1));
  border-bottom: solid 1px #222227;

  .banner__panel{
    @include display-block;
    color: #fff;
    max-width: 1200px;
    margin: 0 auto;

    .banner__title{
      @include display-block;
      @include gradient-text(#adf8fc,#f3b6e5);
      font:700 65px/80px 'Work Sans', sans-serif;
      text-align: center;
    }

    .banner__btns{
      @include flex-row;
      @include justify-space-between;
      margin: 100px auto 0;
      width: 600px;

      .banner__btn{
        @include flex-row;
        width: 285px;

        font:400 22px/28px 'Inter', sans-serif;
        text-align: center;
        color: #ddd;
        padding: 15px 0;
        background:url("../../../public/asset/ui/btn1.png") no-repeat center/cover;
        background-size:100% 100%;
        &:hover{
          background-image:url("../../../public/asset/ui/btn2.png");
          color: #fff;
        }

        &:last-child{
          background-image: url("../../../public/asset/ui/btn2.png");
          &:hover {
            background-image: url("../../../public/asset/ui/btn1.png");
          }
        }
      }
    }
  }


   @media(max-width:992px){
     padding: 60px 0 80px;
     .banner__panel{
       max-width: inherit;
       padding: 0 10px;
       .banner__title{
         font:700 60px/75px 'Work Sans', sans-serif;
       }
       .banner__btns{
         margin: 60px auto 0;
       }
     }
   }

   @media(max-width:768px){
     .banner__panel{
       .banner__title{
         font:700 50px/60px 'Work Sans', sans-serif;
       }
       .banner__btns{
         width: 480px;
         .banner__btn{
           width: 230px;
           padding: 12px 0;
         }
       }
     }
   }
   @media(max-width:576px){
     padding: 30px 0 40px;
     .banner__panel{
       .banner__title{
         font:600 30px/45px 'Work Sans', sans-serif;
       }
       .banner__btns{
         margin: 30px auto 0;
         width: 96%;
         .banner__btn{
           width: calc(50% - 10px);
           font:400 16px/18px 'Inter', sans-serif;
         }
       }
     }
   }
}





.set__up__domain{
  @include display-block;
  overflow: hidden;

  .set__up__domain__header{
    @include flex-row;

    .set__up__domain__header__icons{
      @include display-block;
      width: auto;
      margin-right: 50px;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      svg{
        @include display-block;
        width: 90px;
        height: auto;
      }
      p{
        @include display-block;
        font:500 25px/30px 'Work Sans', sans-serif;
        color: #ccc;
        margin-top: 20px;
        text-align: center;
      }
      span{
        @include display-block;
        visibility: hidden;
        width: 20px;
        height: 20px;
        margin: 10px auto 0;
        border-radius: 50%;
        background: #fff;
      }
      &.active{
        svg{
          path{
            fill: #fff;
          }
        }
        p{
          color: #fff;
        }
        span{
          visibility: visible;
        }
      }
    }
  }

  .set__up__domain__imgs{
    @include flex-row;
    margin-top: 60px;
    padding: 0 20px;
    overflow: hidden;
    width: 120%;
    transition: margin-left 2s;

    &.search{
      margin-left: 0;

    }
    &.register{
      margin-left: -10%;
    }
    &.manage{
      margin-left: -20%;
    }
    .set__up__domain__img{
      @include display-block;
      width: 30%;
      margin-right: 20px;
      border:solid 2px #222227;
      border-radius: 10px;
      overflow: hidden;
      transition: width 2s;
      &:last-child{
        margin-right: 0;
      }
      img{
        @include display-block;
        object-fit: cover;
        width: 100%;
        height: auto;
      }
      &.active{
        width: calc(60% - 40px);
        border-radius: 20px;
      }
    }
  }

  @media(max-width:768px){

    .set__up__domain__header{
      .set__up__domain__header__icons{
        margin-right: 40px;
        svg{
          width: 80px;
        }
        p{
          font:500 20px/25px 'Work Sans', sans-serif;
          margin-top: 10px;
        }
        span{
          width: 15px;
          height: 15px;
          margin: 5px auto 0;
        }
      }
    }
  }
  @media(max-width:576px){

    .set__up__domain__header{
      .set__up__domain__header__icons{
        margin-right: 25px;
        svg{
          width: 60px;
        }
        p{
          font:400 16px/20px 'Work Sans', sans-serif;
        }
        span{
          width: 12px;
          height: 12px;
        }
      }
    }
    .set__up__domain__imgs{
      margin-top: 30px;
      padding: 0;
      width: 180%;
      &.register{
        margin-left: -40%;
      }
      &.manage{
        margin-left: -80%;
      }
      .set__up__domain__img{
        width: 50%;
        margin-right: 10px;
        border:solid 1px #222227;
        border-radius: 5px;
        &.active{
          width: calc(80% - 20px);
          border-radius: 10px;
        }
      }
    }
  }
}





.home__user__example{
  @include flex-row;
  border: solid 1px  #555;
  border-radius: 10px;
  width: max-content;
  margin: 50px auto 40px;
  padding: 10px 50px;
  background: #16151a;
  .bg__shadow{
    position: absolute;
    display: block;
    z-index: -1;
    top: calc(100% - 5px);
    left: 0%;
    width: 100%;
    height: 40px;

    background: -webkit-linear-gradient(180deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);
    background: -moz-linear-gradient(180deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);
    background: -ms-linear-gradient(180deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);
    background: -o-linear-gradient(180deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(180deg, rgb(54, 74, 101) -5.71%, rgba(0, 0, 0, 0) 100%);

    -webkit-clip-path: polygon( 100% 0%, 0% 0%,30px 100%, calc(100% - 30px) 100%,);
    -moz-: polygon( 100% 0%, 0% 0%,30px 100%, calc(100% - 30px) 100%);
    -ms-: polygon( 100% 0%, 0% 0%,30px 100%, calc(100% - 30px) 100%);
    -o-: polygon( 100% 0%, 0% 0%,30px 100%, calc(100% - 30px) 100%);
    clip-path: polygon( 100% 0%, 0% 0%,30px 100%, calc(100% - 30px) 100%);
  }
  img{
    @include display-block;
    width: 60px;
    height: auto;
    filter: brightness(70%);
    margin-right: 10px;
  }
  p{
    @include display-block;
    width: auto;
    font:400 35px/40px 'Work Sans', sans-serif;
    color: #ccc;
  }

  @media(max-width:768px){
    margin: 40px auto 30px;
    padding: 10px 40px;

    .bg__shadow{
      height: 30px;
      -webkit-clip-path: polygon( 100% 0%, 0% 0%,20px 100%, calc(100% - 20px) 100%);
      -moz-: polygon( 100% 0%, 0% 0%,20px 100%, calc(100% - 20px) 100%);
      -ms-: polygon( 100% 0%, 0% 0%,20px 100%, calc(100% - 20px) 100%);
      -o-: polygon( 100% 0%, 0% 0%,20px 100%, calc(100% - 20px) 100%);
      clip-path: polygon( 100% 0%, 0% 0%,20px 100%, calc(100% - 20px) 100%);
    }

    img{
      width: 50px;
      margin-right: 10px;
    }
    p{
      font:400 30px/35px 'Work Sans', sans-serif;
    }
  }
  @media(max-width:576px){
    width: 80%;
    margin: 30px auto 30px;
    padding: 6px 0;
    img{
      width: 40px;
      margin-right: 6px;
    }
    p{
      font:400 22px/25px 'Work Sans', sans-serif;
    }
  }
}


.home__addresses__example{
  @include flex-row;
  @include justify-space-between;
  width: 80%;
  margin: 80px auto 0;
  img{
    @include display-block;
    width: calc(10% - 10px);
    height: auto;
    object-fit: contain;
    filter: brightness(60%) grayscale(80%);
    transition: filter 1s;
    &:hover{
      filter: brightness(100%) grayscale(0%);
    }
  }

  @media(max-width:992px){
    width: 90%;
    img{
      width: calc(10% - 5px);
    }
  }
  @media(max-width:768px){
    flex-wrap: wrap;
    margin-top: 60px;
    width: 80%;
    img{
      width: calc(20% - 10px);
      padding: 10px 5px;
    }
  }
  @media(max-width:576px){
    margin-top: 40px;
    width: 90%;
    img{
      width: calc(20% - 8px);
      padding: 8px 4px;
    }
  }
}


.scroll_btn{
  @include flex-row;
  position: fixed;
  right: 50px;
  bottom: 150px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: #222227;
  z-index: 2;
  svg{
    transform: rotate(180deg);
    transition: stroke .5s;
    stroke:#aaa;
  }
  &:hover{
    svg{
      stroke:#fff;
    }
  }
  @media(max-width:992px){
    right: 30px;
  }
  @media(max-width:576px){
    bottom: 100px;
  }
}
