@import 'mixin';





$cardColor: rgb(97, 100, 255);

@mixin box_shadow($color:rgb(97, 100, 255)){
  .box_shadow{
    display: block;
    position: absolute;
    z-index: -1;
    bottom: calc(100% - 7px);
    left: 0%;
    width: 100%;
    height: 70px;
    background: linear-gradient(0deg, $color -5.71%, rgba(0, 0, 0, 0) 100%);
    clip-path: polygon(60px 0%, calc(100% - 60px) 0%, 100% 100%, 0% 100%);

    &.shadow__sm{
      height: 50px;
      bottom: calc(100% - 6px);
      clip-path: polygon(30px 0%, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
    }
    &.shadow__below{
      height: 70px;
      top: calc(100% - 6px);
      background: linear-gradient(180deg, $color -5.71%, rgba(0, 0, 0, 0) 100%);
      clip-path: polygon(0 0,60px 100%,calc(100% - 60px) 100%,100% 0);
    }
    &.shadow__below__sm{
      height: 50px;
      top: calc(100% - 7px);
      background: linear-gradient(180deg, $color -5.71%, rgba(0, 0, 0, 0) 100%);
      clip-path: polygon(0 0,30px 100%,calc(100% - 40px) 100%,100% 0);
    }
    @media(max-width:992px){
      bottom: calc(100% - 5px);
      height: 50px;
      clip-path: polygon(40px 0%, calc(100% - 40px) 0%, 100% 100%, 0% 100%);
      &.shadow__sm{
        height: 40px;
        bottom: calc(100% - 5px);
        clip-path: polygon(30px 0%, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
      }
      &.shadow__below{
        height: 50px;
        top: calc(100% - 6px);
        clip-path: polygon(0 0,40px 100%,calc(100% - 40px) 100%,100% 0);
      }
      &.shadow__below__sm{
        height: 40px;
        top: calc(100% - 5px);
        clip-path: polygon(0 0,30px 100%,calc(100% - 30px) 100%,100% 0);
      }
    }
    @media(max-width:768px){
      height: 40px;
      clip-path: polygon(30px 0%, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
      &.shadow__sm{
        height: 30px;
        bottom: calc(100% - 5px);
        clip-path: polygon(25px 0%, calc(100% - 25px) 0%, 100% 100%, 0% 100%);
      }
      &.shadow__below{
        height: 40px;
        top: calc(100% - 5px);
        clip-path: polygon(0 0,30px 100%,calc(100% - 30px) 100%,100% 0);
      }
      &.shadow__below__sm{
        height: 30px;
        top: calc(100% - 5px);
        clip-path: polygon(0 0,25px 100%,calc(100% - 25px) 100%,100% 0);
      }
    }
  }
}

@mixin shadow-formate($color) {
  background: rgba($color,1);
  border: solid 1px $color;
  box-shadow: none;
  @include box_shadow($color);
}

.card__banner{
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto 0;
  background: url("../images/banner1.jpg") center/cover;
  box-shadow: inset 480px 0 80px rgba($cardColor,.5);
  padding: 50px 30px;
  border-radius: 15px;
  border: solid 1px $cardColor;
  @include box_shadow();


  &.azure__shadow{
    @include shadow-formate(#364a65);
  }
  &.cyan__shadow{
    @include shadow-formate(#005777);
  }

  &.magenta__shadow{
    @include shadow-formate(#d0d);
  }
  &.brown__shadow{
    @include shadow-formate(#a96821);
  }

  &.overlayed__banner{
    transform: translateY(-45%);
    border-radius: 4px 4px 15px 15px;
    margin-bottom: -100px;
    .box_shadow{
    bottom: 100%;
    }
  }

  h1{
    font:600 55px/70px 'Ubuntu', sans-serif;
    color: #fff;
    text-shadow: 0 2px 2px rgb(37,4,30);
  }
  h2{
    font:600 45px/50px 'Ubuntu', sans-serif;
    color: #ddd;
    text-shadow: 0 2px 2px rgb(37,4,30);

  }
  p{
    margin-top: 20px;
    color: #ddd;
    text-shadow: 0 0 2px rgb(37,4,30);
    font:500 20px/22px 'Inter', sans-serif;
    width: 60%;
  }
  @media(max-width:992px){
    padding: 30px 20px;
    h1{
      font:600 45px/50px 'Ubuntu', sans-serif;
      }
    h2{
      font:600 35px/40px 'Ubuntu', sans-serif;
    }
    p{
      font:500 18px/20px 'Inter', sans-serif;
      width: 80%;
    }
    &.overlayed__banner{
      margin-bottom: -140px;
    }
  }
  @media(max-width:768px){
    padding: 20px 15px;
    h1{
      font:600 35px/40px 'Ubuntu', sans-serif;
      text-shadow: 0 1px 1px rgb(37,4,30);
      }
    h2{
      font:600 30px/35px 'Ubuntu', sans-serif;
      text-shadow: 0 1px 1px rgb(37,4,30);
    }
    p{
      font:500 16px/20px 'Inter', sans-serif;
      text-shadow: 0 0 1px rgb(37,4,30);
      width: 100%;
    }
    &.overlayed__banner{
      margin-bottom: -100px;
    }
  }

}
