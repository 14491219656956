@import '../../scss/mixin';


.page__not_found{

  h1{
    @include display-block;
    width: auto;
    font:600 150px/170px 'Inter', sans-serif;
    color: #aaa;
    margin-bottom: 40px;
  }
  p{
    @include display-block;
    width: auto;
    font:400 40px/50px 'Inter', sans-serif;
    color: #aaa;
    text-align: center;
  }

  @media(max-width:992px){
    p{
      font:400 35px/40px 'Inter', sans-serif;
    }
  }
  @media(max-width:768px){
    h1{
      font:600 120px/130px 'Inter', sans-serif;
    }
    p{
      font:400 25px/35px 'Inter', sans-serif;
    }
  }
  @media(max-width:576px){
    h1{
      font:600 100px/110px 'Inter', sans-serif;
      margin-bottom: 10px;
    }
    p{
      font:400 16px/22px 'Inter', sans-serif;
    }
  }
}
