@import '../../../scss/mixin';


.subdomain__header{
  @include flex-row;
  @include justify-space-between;
  margin-bottom: 20px;

  .subdomain__count{
    @include display-block;
    width: auto;
    font:400 16px/25px 'Inter', sans-serif;
    color: #ccc;
  }
  .subdomain__create{
    @include flex-row;
    width: auto;
    font:400 16px/25px 'Inter', sans-serif;
    color: #ccc;
    text-decoration: underline #364a65;
    svg{
      fill:#364a65;
      transform: rotateZ(180deg);
      height: 25px;
      width: auto;
      margin-right: 5px;
    }
    &:hover{
      .tooltip{display: block}
      color: #fff;
      text-decoration-color: #005777;
      svg{
        fill:#005777;
      }
    }
  }

  .subdomain__create__form{
    @include display-block;

    p{
      @include display-block;
      font:400 15px/25px 'Inter', sans-serif;
      color: #aaa;
    }
    .subdomain__create__form__input{
      @include display-block;
      font:400 18px/25px 'Inter', sans-serif;
      background: transparent;
      border: none;
      color: #ccc;
      padding: 8px 10px;
      margin-top: 40px;
      border: solid 2px #777;
      border-radius: 6px;
      &::placeholder{
        color: #777;
      }
      &:hover{
        border-color: #364a65;
      }
      &:focus{
        border-color: #005777;
      }
    }
    .subdomain__create__form__output{
      @include display-block;
      width: auto;
      margin-top: 5px;
      font:400 14px/16px 'Inter', sans-serif;
      color: #999;
    }
    button{
      @include flex-row;
      font:400 16px/18px 'Inter', sans-serif;
      color: #ccc;
      padding: 10px 0;
      border-radius: 6px;
      background: #364a65;
      margin: 20px 0;
      &:hover{
        background: #005777;
        color: #fff;
      }
      &:disabled{
        background: #222227;
        color: #aaa;
      }
    }

    @media(max-width:768px){
      padding: 0 5px;
      p{
        font:400 14px/22px 'Inter', sans-serif;
      }
      .subdomain__create__form__input{
        font:400 14px/20px 'Inter', sans-serif;
        padding: 8px 5px;
        margin-top: 30px;
        border-width: 1px;
      }
      .subdomain__create__form__output{
        font:400 13px/15px 'Inter', sans-serif;
      }
      button{
        font:400 14px/18px 'Inter', sans-serif;
        padding: 8px 0;
        margin: 15px 0 20px;
      }

    }
  }


  @media(max-width:768px){
    margin-bottom: 10px;
    .subdomain__count{
      font:400 14px/20px 'Inter', sans-serif;
    }
    .subdomain__create{
      font:400 14px/20px 'Inter', sans-serif;
      svg{
        height: 20px;
        margin-right: 0;
      }
    }
  }
}




.subdomain__loadmore{
  @include flex-row;
  font:400 16px/18px 'Inter', sans-serif;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  border-radius: 6px;
  background: #364a65;
  &:hover{
    background: #005777;
    color: #fff;
    .tooltip{display: block;}
  }
  &:disabled{
    background: #222227;
    color: #aaa;
  }
}
