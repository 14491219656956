@import '../../scss/mixin';

@keyframes stretch {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.modal{

  @include display-block;
  position: fixed;
  z-index: 2;
  top: 0;
  padding-top: 70px;
  left: 0;
  bottom: 0;
  right:0;
  overflow: hidden;
  backdrop-filter: blur(2px);

  &.modal__sm{
    .modal__box{
      max-width: 480px;
    }
  }

  .modal__box{
    @include display-block;
    max-width: 680px;
    max-height: calc(100vh - 130px);
    margin: 60px auto 0;
    padding: 2px;
    border-radius: 12px;
    overflow: hidden;
    animation: stretch 0.4s ease-out;
    @include psuedo-bg(0,12px);
    &:before{
      @include gradient-border-bg(#adf8fc,#f3b6e5);
    }
    .modal__box__head{
      @include flex-row;
      background-color: #16151a;
      border-radius: 12px 12px 0 0;
      overflow: hidden;
      margin-bottom: 1px;
      height: 50px;
      .popup__title{
        @include display-block;
        @include gradient-text(#adf8fc,#f3b6e5);
        text-align: center;
        width: max-content;
        margin: 13px auto ;
        font:700 22px/24px 'Inter', sans-serif;
      }
      .popup__close{
        @include display-block;
        width: auto;
        position: absolute;
        top: 5px;
        right: 8px;
        svg {
          fill: #888;
          transition: 0.5s ease;
          height: auto;
          width: 40px;
        }
        &:hover svg {
          fill: #fff;
        }
      }
    }

    .modal__box__inner{
      @include display-block;
      // @include scroll-bar(8px,20px);
      padding: 20px;
      height: calc(100% - 51px);
      background-color: #16151a;
      border-radius: 0 0 12px 12px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  @media(max-width: 768px){
    .modal__box{
      width: 94%;
      padding: 1px;
      .modal__box__head{

        .popup__title{
          margin: 15px auto ;
          font:600 18px/20px 'Inter', sans-serif;
        }
        .popup__close{
          top: 7px;
          svg {
            width: 36px;
          }
        }
      }
      .modal__box__inner{
        // @include scroll-bar(8px,10px);
        padding: 10px;
      }
    }
  }
}



.tooltip{
  position: absolute;
  display: none;
  left: calc(50% - 100px);
  bottom: calc(100% + 10px);
  width: 200px;
  z-index: 1;
  background: #555;
  border-radius: 3px;
  padding: 4px;

  &:after{
    position: absolute;
    display: block;
    content: '';
    top: 100%;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #555;
  }

  .tooltip__text{
    @include display-block;
    width: auto;
    text-align: justify;
    text-align-last: left;
    color: #ccc;
    font:400 13px/18px 'Inter', sans-serif;
  }
  @media(max-width: 768px){
    left: auto;
    right: 0;
    &:after{
      left: calc(100% - 55px);
    }
  }
  @media(max-width: 768px){
    &:after{
      left: calc(100% - 47px);
    }
  }
}
