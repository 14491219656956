
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Oswald:wght@400;500;600&family=Spline+Sans:wght@400;500;600&family=Ubuntu:ital,wght@0,400;0,500;1,400&family=Work+Sans:wght@400;500;700&display=swap');
@import 'mixin';

#root{
  position: relative;
  display: block;
}


.main {
  @include display-block;
  margin: 100px 0 60px;
  &.main___fullscr{
    min-height: calc(100vh - 160px);
  }
  &.main__search__page{
    @include flex-column;
    @include justify-center;
    @include align-center;
    background: rgb(37,4,30);
    background:linear-gradient(165deg, rgba(37,4,30,1), rgba(22,21,26,1));
    height: 100vh;
    margin: 0;
  }
  &.main__full__page{
    @include flex-column;
    @include justify-center;
    @include align-center;
    $bgColor:rgba(#111,.3);

    background-image: -webkit-repeating-radial-gradient( circle at 50% 50%,$bgColor 0,transparent 1%,transparent 3% );
    background-image: -moz-repeating-radial-gradient( circle at 50% 50%,$bgColor 0,transparent 1%,transparent 3% );
    background-image: -ms-repeating-radial-gradient( circle at 50% 50%,$bgColor 0,transparent 1%,transparent 3% );
    background-image: -o-repeating-radial-gradient( circle at 50% 50%,$bgColor 0,transparent 1%,transparent 3% );
    background-image: repeating-radial-gradient( circle at 50% 50%,$bgColor 0,transparent 1%,transparent 3% );
    height: 100vh;
    margin: 0;
  }
  @media(max-width:992px){
    margin: 80px 0 60px;
  }
  @media(max-width:576px){
    padding-bottom: 30px;
  }
}

.section__model{
  @include display-block;
  border-bottom: solid 1px  #222227;
  $bgColor:rgba(#111,.3);
  padding: 100px 0;
  &.bg__left{
    z-index: 0;
    background: none;
    background-image: -webkit-repeating-radial-gradient( circle at 0 100%,$bgColor 0,transparent 1%,transparent 3% );
    background-image: -moz-repeating-radial-gradient( circle at 0 100%,$bgColor 0,transparent 1%,transparent 3% );
    background-image: -ms-repeating-radial-gradient( circle at 0 100%,$bgColor 0,transparent 1%,transparent 3% );
    background-image: -o-repeating-radial-gradient( circle at 0 100%,$bgColor 0,transparent 1%,transparent 3% );
    background-image: repeating-radial-gradient( circle at 0 100%,$bgColor 0,transparent 1%,transparent 3% );
    }

  &.bg__right{
    z-index: 0;
    background: none;
    background-image: -webkit-repeating-radial-gradient( circle at  100% 0,$bgColor 0,transparent 1%,transparent 3% );
    background-image: -moz-repeating-radial-gradient( circle at  100% 0,$bgColor 0,transparent 1%,transparent 3% );
    background-image: -ms-repeating-radial-gradient( circle at  100% 0,$bgColor 0,transparent 1%,transparent 3% );
    background-image: -o-repeating-radial-gradient( circle at  100% 0%,$bgColor 0,transparent 1%,transparent 3% );
    background-image: repeating-radial-gradient( circle at 100% 0,$bgColor 0,transparent 1%,transparent 3% );
  }
  &.mini__margin{
    padding: 40px 0;
  }
  .section__title{
    @include display-block;
    @include gradient-text(#adf8fc,#f3b6e5);
    text-align: center;
    color:#aaa;
    font:500 55px/60px 'Inter', sans-serif;
    margin-bottom: 40px;
  }
  .section__desc{
    @include display-block;
    width: 80%;
    margin: 0 auto 10px;
    text-align: center;
    color:#aaa;
    font:400 25px/35px 'Inter', sans-serif;
  }


  @media(max-width:992px){
    padding: 80px 0;
    &.mini__margin{
      padding: 30px 0;
    }
    .section__title{
      font:500 45px/55px 'Inter', sans-serif;
    }
    .section__desc{
      width: 90%;
      font:400 22px/32px 'Inter', sans-serif;
    }
  }
  @media(max-width:768px){
    padding: 60px 0;
    &.mini__margin{
      padding: 20px 0;
    }
    .section__title{
      font:500 40px/50px 'Inter', sans-serif;
      margin-bottom: 30px;
    }
    .section__desc{
      width: 90%;
      font:400 20px/28px 'Inter', sans-serif;
    }
  }
  @media(max-width:576px){

    .section__title{
      font:500 30px/40px 'Inter', sans-serif;
      margin-bottom: 20px;
      padding: 0 10px;
    }
    .section__desc{
      padding: 0 10px;
      width: 100%;
      font:400 18px/24px 'Inter', sans-serif;
    }
  }
}




.ball__pulse{
  margin-left: 8px;
  div{
    display: inline-block;
    background-color: #fff;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    &:nth-child(1) {
      -webkit-animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
              animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
            }

    &:nth-child(2) {
      -webkit-animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
              animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
            }
    &:nth-child(3) {
      -webkit-animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
              animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
            }
  }
}

@keyframes scale {
0% {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }
45% {
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  opacity: 0.7; }
80% {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; } }






  .owl-dots {
   position: relative;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: baseline;
   width: 100%;
   margin-top: 25px;

   .owl-dot {
    margin-right: 8px;
    &:last-child {
     margin-right: 0;
     }
     span {
      display: block;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: #666;
      transition: 0.5s ease;
     }
      &.active span {
       background-color: #364a65;
      }
   }
 }
