@import '../../scss/mixin';



.large__title{
  @include display-block;
  h1{
    @include display-block;
    @include gradient-text(#adf8fc,#f3b6e5);
    width: max-content;
    margin: 160px auto 10px;
    font:600 100px/110px 'Inter', sans-serif;
  }
  p{
    @include display-block;
    width: auto;
    font:400 30px/35px 'Inter', sans-serif;
    color: #999;
    text-align: center;
  }


  @media(max-width:768px){
    h1{
      font:600 80px/90px 'Inter', sans-serif;
      margin: 140px auto 10px;
    }
    p{
      font:400 25px/30px 'Inter', sans-serif;
    }
  }
  @media(max-width:576px){
    h1{
      font:600 60px/70px 'Inter', sans-serif;
      margin: 100px auto 10px;
    }
    p{
      font:400 18px/22px 'Inter', sans-serif;
    }
  }
}



.roadmap{
  @include display-block;
  width: 80%;
  margin: 40px auto 0;
  .roadmap__stage{
    @include flex-row;
    @include justify-end;
    &:after{
      display: block;
      position: absolute;
      content: "";
      left: 50%;
      top: calc(50% - 1px);
      width: 50%;
      height: 2px;
      background: #364a65;
      z-index: 0;
    }
    &:first-child{
      .roadmap__line{
        border-top-width: 8px;
        &:before{
          top: calc(50% - 14px);
        }
      }
    }
    &:last-child{
      .roadmap__line{
        border-bottom-width: 8px;
        &:before{
          top: calc(50% - 6px);
        }
      }
    }
    .roadmap__line{
      @include display-block;
      position: absolute;
      left: calc(50% - 20px);
      width: 40px;
      height: 100%;
      border: double 8px #333;
      border-top-width: 0;
      border-bottom-width: 0;
      &:before{
        display: block;
        position: absolute;
        content: "";
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: solid 4px #364a65;
        background: #222227;
      }

    }
    .roadmap__content{
      @include display-block;
      width: auto;
      max-width: calc(50% - 30px);
      border: solid 2px #364a65;
      border-radius: 8px;
      overflow: hidden;
      background: #16151a;
      margin: 10px 0;
      z-index: 2;

      .stage__title{
        @include display-block;
        background: #222227;
        border-bottom: solid 1px #364a65;
        padding: 6px 10px;
        h2{
          @include display-block;
          @include gradient-text(#adf8fc,#f3b6e5);
          width: max-content;
          font:600 18px/20px 'Inter', sans-serif;
        }
      }
      .stage__desc{
        @include display-block;
        font:400 16px/20px 'Inter', sans-serif;
        color: #bdbdbd;
        padding: 20px 10px;
      }
    }
  }

  @media(min-width:992.1px){
    .roadmap__stage{
      &.roadmap__stage__left{
        @include justify-start;
        &:after{
          left: 0%;
        }
      }
    }
  }
  @media(max-width:992px){
    .roadmap__stage{

      &:after{
        left: 10px;
        width: calc(100% - 10px);
      }
      .roadmap__line{
        left: 0;
      }
      .roadmap__content{
        max-width: calc(100% - 20px);
        margin: 20px 0;
      }
    }
  }
  @media(max-width:768px){
    width: calc(100% - 80px);
    .roadmap__stage{
      .roadmap__content{
        max-width: calc(100% - 50px);
      }
    }
  }
  @media(max-width:576px){
    width: calc(100% - 30px);
    margin: 40px auto 0;
    .roadmap__stage{
      .roadmap__content{
        margin: 15px 0;
      }
    }
  }
}



.terminology{
  @include display-block;
  width: 80%;
  margin: 40px auto 0;
  p{
    @include display-block;
    font:400 16px/24px 'Inter', sans-serif;
    color: #bbb;
    list-style-type: circle;
    margin: 20px 0;
    padding-left: 20px;
    &:before{
      display: block;
      position: absolute;
      content: "";
      left: 0;
      top: 6px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: solid 3px #364a65;
      background: #999;
    }
  }

  @media(max-width:992px){
    width: 90%;
  }
  @media(max-width:768px){
    width: calc(100% - 30px);
    margin: 40px auto 0;
  }
}





.faq__container{
  @include display-block;
  width: 80%;
  margin: 40px auto 0;
  // background: red;

  .faq__content{
    @include display-block;
    margin-bottom: 35px;
    border: solid 2px #364a65;
    border-radius: 5px;
    overflow: hidden;
    background: #16151a;
    &:last-child{
      margin-bottom: 0;
    }
    .faq__content__head{
      @include display-block;
      background: #222227;
      border-bottom: solid 1px #364a65;
      padding: 12px 15px;
      font:500 18px/25px 'Inter', sans-serif;
      color: #bdbdbd;
    }
    .faq__content__body{
      @include display-block;
      font:400 16px/20px 'Inter', sans-serif;
      color: #bdbdbd;
      padding: 20px 12px;
      white-space:pre-line;

    }
  }

  @media(max-width:992px){
    width: 90%;
    .faq__content{
      margin-bottom: 25px;
    }
  }

  @media(max-width:576px){
    width: calc(100% - 30px);
    margin: 40px auto 0;
  }
}
