
@import '../../scss/mixin';



.search___title{
  @include display-block;
  width: auto;
  margin-bottom: 50px;
  svg{
    @include display-block;
    width: 510px;
    height: auto;
  }
  @media(max-width:992px){
    svg{
      width: 450px;
    }
  }
  @media(max-width:768px){
    margin-bottom: 40px;
    svg{
      width: 380px;
      height: auto;
    }
  }
  @media(max-width:576px){
    width: 100%;
    margin: 30px;
    svg{
      max-width: 60%;
      margin: 0 auto;
      height: auto;
    }
  }
}


.noresult__notice{
  @include display-block;
  text-align: center;
  margin: 40px 0;
  font:400 35px/38px 'Inter', sans-serif;
  color: #555;
  @media(max-width:992px){
    margin: 20px 0;
  }
  @media(max-width:768px){
    margin: 10px 0;
    font:400 25px/28px 'Inter', sans-serif;
  }
}
