@import '../../../scss/mixin';


.name__details{
  @include display-block;
  .name__registry__details{
    @include display-block;
    padding-bottom: 30px;
    border-bottom: dotted 2px #222227;

    .advance__settings{
      @include flex-row;
      @include justify-start;
      width: max-content;
      margin-top: 40px;
      font:500 22px/25px 'Inter', sans-serif;
      color: #ddd;
      cursor: pointer;
      &.active{
        svg{transform: rotateZ(90deg)}
      }
      svg{
        transform: rotateZ(270deg);
        margin-left: 6px;
        fill:#888;
        height: 22px;
        width: auto;
      }
      @media(max-width:768px){
        margin-top: 30px;
        font:500 20px/25px 'Inter', sans-serif;
        svg{
          margin-left: 6px;
          height: 20px;
        }
      }
      @media(max-width:576px){
        margin-top: 30px;
        font:500 18px/22px 'Inter', sans-serif;
        svg{
          margin-left: 4px;
          height: 18px;
        }
      }
    }
    .name__registry__details__each{
      @include flex-row;
      @include justify-space-between;
      margin: 20px 0;
      &:first-child{
        margin-top: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
      h4{
        @include display-block;
        width: auto;
        font:400 16px/18px 'Inter', sans-serif;
        color: #aaa;
        width: 140px;
        margin-right: 40px;
      }
      .address__view{
        width: 100%;
        flex: 1;
      }
      P,>a{
        @include display-block;
        flex: 1;
        font:400 16px/18px 'Inter', sans-serif;
        color: #aaa;
      }
      >a{
        @include ellipsis-text;
        font:500 22px/25px 'Inter', sans-serif;
        &:hover{
          text-decoration: underline;
          color: #ccc;
        }
      }
      .details__handle__btn{
        @include display-block;
        width: 100px;
        font:400 16px/18px 'Inter', sans-serif;
        text-align: center;
        color: #ccc;
        padding: 10px 0;
        border-radius: 6px;
        background: #364a65;
        &:hover{
          background: #005777;
          color: #fff;
          .tooltip{display: block;}
        }
        &:disabled{
          background: #222227;
          color: #aaa;
        }
      }


      @media(max-width:768px){
        >a{
          font:500 18px/20px 'Inter', sans-serif;
        }
        h4{
          font:400 14px/16px 'Inter', sans-serif;
          width: 125px;
          margin-right: 10px;
        }
        P{
          font:400 14px/16px 'Inter', sans-serif;
        }
        .details__handle__btn{
          width: 90px;
          font:400 14px/16px 'Inter', sans-serif;
        }
      }
      @media(max-width:576px){
        >a{
          font:500 16px/18px 'Inter', sans-serif;
        }
        h4{
          width: 95px;
        }
        .details__handle__btn{
          width: 75px;
          padding: 8px 0;
        }
      }
    }
  }


  .name__registry__details__each .record__popup__form,.record__popup__form{
    @include display-block;

    p{
      @include display-block;
      font:400 15px/25px 'Inter', sans-serif;
      color: #aaa;
    }
    .record__popup__form__input{
      @include display-block;
      font:400 18px/25px 'Inter', sans-serif;
      background: transparent;
      border: none;
      color: #ccc;
      padding: 8px 10px;
      margin-top: 40px;
      border: solid 2px #777;
      border-radius: 6px;
      &::placeholder{
        color: #777;
      }
      &:hover{
        border-color: #364a65;
      }
      &:focus{
        border-color: #005777;
      }
    }
    textarea.record__popup__form__input{
      @include scroll-bar2();
      min-height: 160px;
      font:400 16px/22px 'Inter', sans-serif;

    }
    .record__popup__form__output{
      @include display-block;
      width: auto;
      margin-top: 5px;
      font:400 14px/16px 'Inter', sans-serif;
      color: #999;
    }
    button{
      @include flex-row;
      font:400 16px/18px 'Inter', sans-serif;
      color: #ccc;
      padding: 10px 0;
      border-radius: 6px;
      background: #364a65;
      margin: 20px 0;
      &:hover{
        background: #005777;
        color: #fff;
      }
      &:disabled{
        background: #222227;
        color: #aaa;
      }
    }

    .name__renew__input{
      @include flex-row;
      border-bottom: solid 1px #555;
      padding:0 10px 10px;
      margin: 40px auto 5px;
      button{
        @include flex-row;
        width: auto;
        background: #364a65;
        border-radius: 50%;
        padding: 10px;
        &:first-child{
          margin-right: 10px;
        }
        svg{
          fill: #ccc;
          width: 20px;
          height: 20px;
        }
      }
      input{
        @include display-block;
        flex: 1;
        background: transparent;
        border: none;
        text-align: center;
        font:400 40px/45px 'Inter', sans-serif;
        color: #ccc;
      }
      p{
        @include display-block;
        width: auto;
        font:400 40px/45px 'Inter', sans-serif;
        color: #ccc;
        margin: 0 10px;
      }
    }
    span{
      @include display-block;
      font:400 16px/18px 'Inter', sans-serif;
      color: #888;
      margin-bottom: 20px;
    }

    @media(max-width:768px){
      padding: 0 5px;
      p{
        font:400 14px/22px 'Inter', sans-serif;
      }
      .record__popup__form__input{
        font:400 14px/20px 'Inter', sans-serif;
        padding: 8px 5px;
        margin-top: 30px;
        border-width: 1px;
      }
      .record__popup__form__output{
        font:400 13px/15px 'Inter', sans-serif;
      }
      button{
        font:400 14px/18px 'Inter', sans-serif;
        padding: 8px 0;
        margin: 15px 0 20px;
      }
      .name__renew__input{
        padding:0 0 6px;
        margin: 30px auto 5px;
        button{
          padding: 6px;
          &:first-child{
            margin-right: 6px;
          }
          svg{
            width: 18px;
            height: 18px;
          }
        }
        input{
          font:400 30px/35px 'Inter', sans-serif;
        }
        p{
          font:400 30px/35px 'Inter', sans-serif;
          margin: 0 6px;
        }
      }
      span{
        font:400 14px/16px 'Inter', sans-serif;
        margin-bottom: 15px;
      }
    }
  }



  .name__record__details{
    @include display-block;
    border: solid 1px #364a65;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 30px;
    .name__record__title{
      @include display-block;
      background: #364a65;
      p{
        @include display-block;
        width: auto;
        font:500 14px/40px 'Inter', sans-serif;
        color: #999;
        margin-left: 20px;
      }
    }

    .name__record__details__box{
      @include flex-row;
      @include align-start;
      padding: 20px 20px;
      &:nth-child(2){
        border-bottom: dotted 2px #222227;
      }
      h3{
        @include display-block;
        font:500 16px/18px 'Inter', sans-serif;
        color: #aaa;
        width: 140px;
        margin-right: 40px;
      }

      ul{
        @include display-block;
        width: calc(100% - 180px);
        li{
          @include flex-row;
          @include justify-start;
          font:400 14px/18px 'Inter', sans-serif;
          margin: 15px 0;
          &:first-child{
            margin-top: 0;
          }
          &:last-child{
            margin-bottom: 0;
          }
          .name__record__details__key{
            @include display-block;
            width: 90px;
            color: #aaa;
            margin-right: 40px;
          }
          .name__record__details__value{
            @include flex-row;
            @include justify-start;
            width: calc(100% - 130px);
            color: #aaa;
            .address__view{
              width: 100%;
              flex: 1;
              .address__view__text{
                font:400 14px/16px 'Inter', sans-serif;
                color: #999;
              }
            }
            .name__record__text{
              @include display-block;
              color: #aaa;
              overflow-wrap: anywhere;
              +.domain__record___edit{
                margin-left: 10px;
              }
            }
            a.name__record__text:hover{
              text-decoration: underline;
            }
            .domain__record___edit{
              svg{
                width: 20px;
                height: auto;
                g{
                  fill:#364a65;
                }
              }
              &:hover svg g{
                fill:#aaa;
              }
            }
          }
        }
      }
      @media(max-width:768px){
        flex-wrap: wrap;
        &:nth-child(2){
          border-bottom: dotted 2px #222227;
          ul li {
            .name__record__details__key{
              width: 50px;
            }
            .name__record__details__value{
              width: calc(100% - 70px);
            }
          }
        }
        h3{
          width: 100%;
          margin-bottom: 20px;
        }
        ul{
          width: 100%;
          li {
            .name__record__details__key{
              width: 80px;
              margin-right: 20px;
            }
            .name__record__details__value{
              width: calc(100% - 100px);
            }
          }
        }
      }
    }
  }
}
