@import '../../../scss/mixin';



.name__register{
  @include display-block;
  min-height: 200px;
  .name__register__body{
    @include display-block;
    max-width: 700px;
    margin: 60px auto;
    .name__register__input{
      @include flex-row;
      border-bottom: solid 1px #555;
      padding-bottom: 10px;
      margin-bottom: 5px;
      button{
        @include flex-row;
        width: 60px;
        height: 60px;
        background: #364a65;
        border-radius: 50%;
        &:first-child{
          margin-right: 10px;
        }
        svg{
          fill: #ccc;
        }
      }
      input{
        @include display-block;
        flex: 1;
        background: transparent;
        border: none;
        text-align: center;
        font:400 45px/50px 'Inter', sans-serif;
        color: #ccc;
      }
      p{
        @include display-block;
        width: auto;
        font:400 45px/50px 'Inter', sans-serif;
        color: #ccc;
        margin: 0 10px;
      }
    }
    .name__register__label{
      @include display-block;
      font:400 16px/18px 'Inter', sans-serif;
      color: #888;
    }

    .name__register__sum{
      @include display-block;
      font:400 30px/35px 'Inter', sans-serif;
      color: #aaa;
      margin: 40px 0 5px;
      border-bottom: solid 1px #555;
      padding-bottom: 10px;
    }

    .name__register__submit{
      @include flex-row;
      background: #364a65;
      font:400 25px/30px 'Inter', sans-serif;
      color: #ccc;
      margin-top: 30px;
      padding: 6px 0;
      border-radius: 5px;
    }

    @media(max-width:768px){
      margin: 20px auto;
      padding: 0 10px;
      .name__register__input{
        padding-bottom: 8px;
        button{
          width: 40px;
          height: 40px;
          &:first-child{
            margin-right: 8px;
          }
          svg{
            height: auto;
            width: 20px;
          }
        }
        input{
          font:400 35px/40px 'Inter', sans-serif;
        }
        p{
          font:400 35px/40px 'Inter', sans-serif;
          margin: 0 8px;
        }
      }
      .name__register__label{
        font:400 14px/16px 'Inter', sans-serif;
      }

      .name__register__sum{
        font:400 22px/25px 'Inter', sans-serif;
        padding-bottom: 8px;
      }

      .name__register__submit{
        font:400 20px/22px 'Inter', sans-serif;
        padding: 8px 0;
      }

    }
    @media(max-width:576px){
      margin: 30px auto 10px;
      padding: 0 5px;
      .name__register__sum{
        font:400 18px/20px 'Inter', sans-serif;
        padding-bottom: 8px;
      }

      .name__register__submit{
        font:400 16px/18px 'Inter', sans-serif;
      }
    }
  }
}
