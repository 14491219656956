
@import '../../scss/mixin';


.free__claim__form{
  @include display-block;
  @include gradient-border-bg(#adf8fc,#f3b6e5);
  padding: 1px;
  width: 800px;
  border-radius: 10px;
  .free__claim__form__body{
    @include display-block;
    padding: 30px;
    background: #16151a;
    border-radius: 10px;
  }

  .title{
    @include display-block;

    h1{
      @include display-block;
      @include gradient-text(#adf8fc,#f3b6e5);
      width: max-content;
      margin: 0 auto;
      font:600 35px/40px 'Inter', sans-serif;
    }
    h2{
      @include display-block;
      @include gradient-text(#FBD72B,#F9484A);
      width: max-content;
      margin: 15px auto 40px;
      font:600 25px/30px 'Inter', sans-serif;
    }
  }

  .free__claim__input{
    @include flex-row;
    height: 80px;
    input{
      @include display-block;
      flex: 1;
      height: 100%;
      font:400 28px/30px 'Inter', sans-serif;
      color: #ccc;
      background: #222227;
      padding: 8px 10px;
      border-radius: 10px 0 0 10px;
      border: solid 2px #364a65;

      &::placeholder{
        color: #777;
      }
      &:focus{
        border-color: #005777;
      }
    }

    .tld__select{
      padding: 0;
      border: none;
      border-radius: 0 10px 10px 0;
      min-width: 100px;

      &.active{
        .tld__options{
          display: block;
        }
      }

      .tld__selected{
        padding: 0 10px;
        height: 100%;
        border-radius: 0 10px 10px 0;
        font:500 28px/30px 'Inter', sans-serif;
        svg{
          width: 20px;
        }
      }
      .tld__options{
        width: 100%;
        top: calc(100% - 5px);
      }
    }
  }
  .free__claim__msg{
    @include display-block;
    font:400 16px/20px 'Inter', sans-serif;
    padding-left: 10px;
    margin-top: 3px;
    color: #999;
  }
  button{
    @include flex-row;
    font:400 22px/30px 'Inter', sans-serif;
    color: #ccc;
    padding: 10px 0;
    margin-top: 40px;
    border-radius: 6px;
    background: #364a65;
    &:hover{
      background: #005777;
      color: #fff;
    }
    &:disabled{
      background: #222227;
      color: #aaa;
    }
  }

  @media(max-width:992px){
    width: 80%;
  }
  @media(max-width:768px){

    width: 90%;
    .free__claim__input{
      height: 70px;
      input{
        font:400 25px/30px 'Inter', sans-serif;
      }
      .tld__select{
        min-width: 80px;
        .tld__selected{
          font:500 24px/28px 'Inter', sans-serif;
          svg{
            width: 16px;
          }
        }
      }
    }
    button{
      margin-top: 30px;
      font:400 20px/25px 'Inter', sans-serif;
    }
  }

  @media(max-width:576px){
    width: calc(100% - 30px);
    border-radius: 8px;
    .free__claim__form__body{
      padding: 15px;
      border-radius: 8px;
    }
    .title{
      h1{
        font:500 28px/32px 'Inter', sans-serif;
      }
      h2{
        margin: 10px auto 30px;
        font:500 22px/25px 'Inter', sans-serif;
      }
    }

    .free__claim__input{
      height: 65px;
      input{
        font:400 20px/25px 'Inter', sans-serif;
        border-radius: 6px 0 0 6px;
      }

      .tld__select{
        min-width: 70px;
        border-radius: 0 6px 6px 0;

        .tld__selected{
          font:400 20px/22px 'Inter', sans-serif;
          padding: 0 5px;
          svg{
            width: 14px;
          }
        }
        .tld__options{
          border-radius: 4px;
          .tld__option{
            font:400 16px/18px 'Inter', sans-serif;
            padding: 4px 0 4px 5px;
          }
        }
      }
    }
    button{
      margin-top: 20px;
      font:400 18px/22px 'Inter', sans-serif;
      border-radius: 6px;
    }
  }

}
