@import '../../scss/mixin';


.search__bar{
  @include flex-row;
  width: 60%;
  height: 100px;
  &.search__bar__full{
    width: 100% !important;
    margin: 30px 0 30px;
  }
  &:focus-within{
    .serach__input,.tld__select{
      border-color: #364a65;
    }
    .serach__btn{
      color: #ccc;
      background: #364a65;
      border-color: #364a65;
    }
  }
  .serach__input{
    @include flex-row;
    flex: 1;
    height: 100%;
    background: #222227;
    padding: 0 10px;
    border-radius: 10px 0 0 10px;
    border: solid 2px #555;
    border-right-width: 0;
    svg{
      width: 35px;
      height: auto;
      fill:#888;
    }
    input{
      @include display-block;
      font:400 30px/42px 'Inter', sans-serif;
      background: transparent;
      border: none;
      color: #fff;
      padding-left: 6px;
      &::placeholder{
        color: #777;
        font:400 30px/42px 'Inter', sans-serif;
      }
    }
  }

  .serach__btn{
    @include display-block;
    width: auto;
    height: 100%;
    background: #555;
    font:400 25px/30px 'Inter', sans-serif;
    border: solid 2px #555;
    border-left-width: 0;
    border-radius: 0 10px 10px 0;
    padding: 0 25px;
    color: #aaa;
    &:hover{
      color: #ccc;
      background: #005777;
      border-color: #005777;
    }
  }



  @media(max-width:1200px){
    width: 70%;
  }
  @media(max-width:992px){
    width: 85%;
    height: 80px;
  }
  @media(max-width:768px){
    width: 90%;
    .serach__input{
      svg{
        width: 28px;
      }
      input{
        font:400 20px/42px 'Inter', sans-serif;
        &::placeholder{
          font:400 20px/42px 'Inter', sans-serif;
        }
      }
    }
    .serach__btn{
      font:400 20px/25px 'Inter', sans-serif;
    }
  }
  @media(max-width:576px){
    width: calc(100% - 30px);
    height: 65px;
    .serach__input{
      padding: 0 5px;
      border-radius: 8px 0 0 8px;
      svg{
        width: 28px;
      }
      input{
        font:400 18px/22px 'Inter', sans-serif;
        &::placeholder{
          font:400 18px/22px 'Inter', sans-serif;
        }
      }
    }
    .serach__btn{
      border-radius: 0 8px 8px 0;
      padding: 0 10px;
      font:400 18px/22px 'Inter', sans-serif;
    }
  }
}




.tld__select{
  @include flex-column;
  @include justify-center;
  width: auto;
  height: 100%;
  border: solid 2px #555;
  border-right-width: 0;
  border-left-width: 0;
  background: #222227;
  padding-right: 10px;
  cursor: pointer;
  &.active{
    .tld__selected{
      color: #fff;
      svg{transform: rotateZ(90deg);fill: #fff;}
    }
    .tld__options{
      display: block;
    }
  }
  .tld__selected{
    @include flex-row;
    font:400 20px/22px 'Inter', sans-serif;
    color: #ccc;
    background: #364a65;
    border-radius: 5px;
    padding: 5px 8px;
    transition: all .5s;

    svg{
      transform: rotateZ(270deg);
      fill: #ccc;
      width: 16px;
      height: auto;
      margin-left: 5px;
    }
    &:hover{
      color: #fff;
      background: #005777;
      svg{fill: #fff;}
    }
  }
  .tld__options{
    @include display-block;
    position: absolute;
    z-index: 1;
    display: none;
    width: calc(100% - 10px);
    top: calc(50% + 20px);
    background: #222227;
    border: solid 1px #364a65;
    border-radius: 5px;
    .tld__option{
      @include display-block;
      transition: all .5s;
      font:400 16px/20px 'Inter', sans-serif;
      color: #999;
      padding: 5px 0 5px 8px;
      border-bottom: solid 1px #364a65;
      &:last-child{
        border-bottom: none;
      }
      &:hover{
        color: #fff;
      }
    }
  }
  @media(max-width:768px){
    .tld__selected{
      font:400 18px/20px 'Inter', sans-serif;
      svg{
        width: 14px;
      }
    }
  }
  @media(max-width:576px){
    padding-right: 5px;
    .tld__selected{
      font:400 18px/20px 'Inter', sans-serif;
      padding: 4px 5px;
      svg{
        width: 14px;
        margin-left: 3px;
      }
    }
    .tld__options{
      width: calc(100% - 5px);
      top: calc(50% + 18px);
      border-radius: 4px;
      .tld__option{
        font:400 16px/18px 'Inter', sans-serif;
        padding: 4px 0 4px 5px;
      }
    }
  }
}


.domain__list{
  @include display-block;
  @include gradient-border-bg(#333961 ,#1b555d );
  padding: 1px;
  margin: 15px 0;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  .domain__list__inner{
    @include flex-row;
    @include justify-space-between;
    background: #16151a;
    border-radius: 9px;
    padding: 25px 20px;
    color: #aaa;
    transition: all 0.5s;

    &:hover{
      background: #222227;
    }
  }
  .domain__list__name{
    @include display-block;
    @include ellipsis-text;
    width: auto;
    font:400 25px/30px 'Inter', sans-serif;
    color: #ccc;
  }
  .domain__list___info{
    @include flex-row;
    @include justify-end;
    width: auto;
    flex-shrink: 0;

    p{
      @include display-block;
      width: auto;
      font:400 15px/18px 'Inter', sans-serif;
      color: #888;
      margin-left: 5px;
      &.domain__list___info__status{
        width: 90px;
        margin-left: 10px;
        text-align: right;
        color: #aaa;
        font:500 16px/18px 'Inter', sans-serif;
      }
    }
  }
  .domain__list__expiry{
    display: none;
  }

  &.dummy__domain__list{
    .domain__list__inner:hover{background: #16151a;}

    .domain__list__name,.domain__list__expiry,.domain__list___info p{
      @include gradient-dummy-bg;
      border-radius: 4px;
    }
    .domain__list__name{
        width: 40%;
        height: 40px;
    }
    .domain__list___info{
      width: 50%;
      p{
        width: 50%;
        height: 30px;
        &.domain__list___info__status{
          width: 90px;

        }
      }
    }
  }


  @media(max-width:992px){
    .domain__list__name{
      font:400 22px/25px 'Inter', sans-serif;
    }

    .domain__list___info{
      flex-shrink: 1;
      p{
        font:400 14px/16px 'Inter', sans-serif;
        min-width: 180px;
        &.domain__list___info__status{
          min-width: inherit;
        }
      }

    }
  }
  @media(max-width:768px){
    .domain__list__inner{
      flex-wrap: wrap;
      border-radius: 9px;
      padding: 20px 15px;
    }
    .domain__list__name{
      max-width: calc(100% - 100px);
    }
    .domain__list___info{
      p{
        display: none;
        &.domain__list___info__status{
          display: block;
        }
      }
    }
    .domain__list__expiry{
      @include display-block;
      font:400 14px/16px 'Inter', sans-serif;
      color: #888;
      margin-top: 5px;
      text-align: right;
    }
  }
}
